<template>
  <div class="questionnaireCard van-hairline--bottom">
    <img v-RandomBgColor  class="cover" :src="itemData.picUrl" alt="" />
    <div class="info">
      <h3>{{itemData.name}}</h3>
      <div class="timeContent">
        <img src="@/assets/img/home/31shijian@2x.png">
       {{itemData.createTime.substring(0,10)}}
        <img class="marL20" src="@/assets/img/home/shijian@2x.png">
        {{itemData.duration}}
      </div>
      <p class="money">￥{{itemData.longmoney && itemData.longmoney > 0 ? itemData.money + '起' : itemData.money}}</p>
    </div>
    <div :class="{stateButton:true, active:itemData.status!==1}">{{getStatusTip(itemData)}}</div>
  </div>
</template>

<script>
  import {Toast} from "vant";

  export default {
    name: "questionnaireCard",
    props:{
      itemData:Object
    },
    data() {
      return {};
    },
    created() {},
    methods: {
      getStatusTip(item){
        if (item.sampleStatus != null){
          switch (item.sampleStatus) {
            case 0:
              return "审核中"
            case 1:
              return "已通过"
            case 2:
              return "已驳回"
            default:
              return "进行中"
          }
        }else {
          if (item.status == 0){
            return "待开始"
          }else if (item.status == 1){
            return "进行中"
          }else {
            return "已结束"
          }
        }
      }
    }
  };
</script>

<style lang="less" scoped>
  .questionnaireCard {
    display: flex;
    align-items: center;
    position: relative;
    height: 240px;
    .cover {
      width: 180px;
      height: 180px;
      border-radius: 16px;
      object-fit: cover;
      margin-right: 20px;
    }

    .info {
      h3 {
        font-size: 32px;
        font-weight: 500;
        color: #333333;
        line-height: 44px;
        margin-bottom: 8px;
      }

      .timeContent {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-weight: 400;
        color: #999999;
        line-height: 40px;
        margin-bottom: 4px;

        img {
          width: 28px;
          height: 28px;
          margin-right: 6px;
        }

        .marL20 {
          margin-left: 20px;
        }
      }

      .money {
        font-size: 28px;
        font-weight: bold;
        color: #D93E34;
        line-height: 40px;
      }
    }

    .stateButton {
      width: 108px;
      height: 52px;
      background: rgba(62, 99, 154, 0.2);
      border-radius: 26px;
      font-size: 24px;
      font-weight: bold;
      color: #3E639A;
      line-height: 52px;
      text-align: center;
      position: absolute;
      right: 0;
      bottom: 40px;
      cursor: pointer;

      &.active {
        background: #EEEEEE;
        color: #CACACA;
      }
    }
  }
</style>

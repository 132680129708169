<template>
  <div class="questionnaire">
    <img class="head-banner" :src="BannerSrc" alt="">
    <van-empty v-if="list.length===0" description="暂无问卷数据"/>
    <van-list class="circleList" v-model="loading" :finished="finished" @load="onLoad">
      <questionnaire-card v-for="item in list" :key="item.id" @click.native="goOutLink(item)"
                          :itemData="item"/>
    </van-list>
  </div>
</template>

<script>
  import questionnaireCard from './components/questionnaireCard'
  import untils from "@/until/until"
  import wxLogin from '@/until/wxLogin.js'
  import {Toast} from "vant";

  export default {
    name: "questionnaire",
    components: {
      questionnaireCard
    },
    data() {
      return {
        loading: false,
        finished: false,
        BannerSrc: '',
        list: [],
        pageNum: 1,
        pageSize: 15
      };
    },
    async mounted() {
      if (!localStorage.sessionKey && untils.isWechat()) {
        const res = await wxLogin.getWechatAppId()
        await wxLogin.wxLogin(window.location.href, res.data.appId)
      } else {
        let UserInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
        if (!UserInfo.phone || UserInfo.phone === null || UserInfo.phone === '') {
          // this.$router.push('/login?type=2')
          if (untils.isWechat()) {
            const res = await wxLogin.getWechatAppId()
            await wxLogin.wxLogin(window.location.href, res.data.appId)
          }
        }
      }

      this.getBanner()
    },
    methods: {
      onLoad() {
        this.$api.home.activityList({
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }, res => {
          if (res.code == 200) {
            if (this.list.length >= res.total) {
              this.finished = true
              return false
            }
            this.list = this.list.concat(res.rows)
            this.pageNum++
            this.loading = false
          }
        })
      },
      //获取banner
      getBanner() {
        this.$api.home.bannerList({
          type: 2
        }, res => {
          if (res.code == 200) {
            this.BannerSrc = res.rows[0].picUrl
          }
        })
      },
      //跳转
      goOutLink(item) {
        if (item.status == 0){
          Toast("该问卷暂未开始，请耐心等待")
        }else if (item.status == 1){
          switch (item.sampleStatus) {
            case 0:
              Toast("审核中，请耐心等待")
              break
            case 1:
              Toast("审核已通过")
              break
            case 2:
              Toast("审核已驳回")
              break
            default:
              window.open(item.accessLinks)
              break
          }
        }else {
          switch (item.sampleStatus) {
            case 0:
              Toast("审核中，请耐心等待")
              break
            case 1:
              Toast("审核已通过")
              break
            case 2:
              Toast("审核已驳回")
              break
            default:
              Toast("该调查已结束")
              break
          }
        }
      }
    }
  };
</script>

<style lang="less" scoped>
  .questionnaire {
    padding: 28px 30px 30px;
    width: 100%;
    box-sizing: border-box;
    background-color: #ffffff;
    min-height: 100vh;

    .head-banner {
      width: 690px;
      height: 240px;
      object-fit: cover;
      border-radius: 15px;
      margin-bottom: 30px;
    }
  }
</style>
